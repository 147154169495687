import { useEffect, useState } from "react";
import Tab from "../Tabs/Tab";
import ITab from "../../interfaces/tab";
import ReleaseDetailForm from "./ReleaseDetailsForm";
import CreateTrackListForm from "./CreateTrackListForm";
import Distribution from "./Distribution";
import { useSearchParams } from "react-router-dom";
import sendRequest from "../axios";
import IRelease, { ReleaseType } from "../../interfaces/release";
import { useCreateReleaseContext } from "./CreateReleaseContext";
import Button from "../Button/Button";
import { toast } from "react-toastify";
import { PuffLoader } from "react-spinners";

interface Props {
  prevRelease?: IRelease;
  callback?: () => void;
}

const NewReleaseForm = ({ prevRelease, callback }: Props) => {
  const { newRelease, release, setRelease } = useCreateReleaseContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const pageTitle = "tabpage";
  const pages = [
    "release-details",
    "release-track-list",
    // "release-royalty-splits",
    "release-distribution",
  ];
  // toast.success("Release saved successfully", {
  //   progressClassName: "!bg-red-600 !text-red-200",
  // });

  useEffect(() => {
    if (prevRelease) {
      console.log("there is a previous release to be edited");
      setRelease(prevRelease);
    }
  }, [prevRelease]);

  function getNextPage(page: string) {
    const index = pages.indexOf(page);
    const lastIndex = pages.length - 1;
    if (index < lastIndex) {
      return pages[index + 1];
    }
    return null;
  }

  function getPreviousPage(page: string) {
    const index = pages.indexOf(page);
    let nextIndex = 0;
    if (index > 0) {
      nextIndex = index - 1;
    }
    return pages[nextIndex];
  }
  const gotoNextTab = () => {
    const nextPage = getNextPage(searchParams.get(pageTitle) || "");
    // if there is a next page, set the search params to the next page
    if (nextPage) {
      setSearchParams((prev) => {
        prev.set(pageTitle, nextPage);
        return prev;
      });
    } else {
      // if there is no next page, close the modal
      callback && callback();
    }
  }

  function next() {
    const stage = searchParams.get(pageTitle);
    let data = newRelease[stage as ReleaseType];
    console.log({ releaseID: data.get("release_id") });
    const entries = data.entries();
    var result = entries.next();
    // console.log(entries, result);
    if (prevRelease && result.done) {
      // there is not data to update, so go to next tab
      gotoNextTab();
      return;
    }
    const method = prevRelease ? "PUT" : "POST";

    setError("");
    setLoading(true);
    return sendRequest(method, `/api/v1/release?stage=${stage}`, data)
      .then((res) => {
        // TODO: set release details here so we can get the id
        // work on tracklist by following what is done on the backend already
        // console.log({ res });
        setRelease(res.data.data);
        gotoNextTab();
        toast.success("Release saved successfully");
        // close the modal and notify the user the draft of the release has been saved
      })
      .catch((err) => {
        console.log(err.response.data);
        setError(err.response.data.errors);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function previous() {
    setSearchParams((prev) => {
      prev.set(
        pageTitle,
        getPreviousPage(searchParams.get(pageTitle) || pages[0])
      );
      return prev;
    });
  }

  const tabs: { [K in ReleaseType]: ITab } = {
    "release-details": {
      name: "Release Details",
      id: "release-details",
      current: false,
      content: <ReleaseDetailForm error={error} />,
      shouldSwitch: () => true,
    },
    "release-track-list": {
      name: "Track List",
      id: "release-track-list",
      current: false,
      content: <CreateTrackListForm />,
      shouldSwitch: () => release.id !== undefined && release.id !== "",
    },
    // "release-royalty-splits": {
    //   name: "Royalty Splits",
    //   id: "release-royalty-splits",
    //   current: true,
    //   content: <RoyaltySplits />,
    // },
    "release-distribution": {
      name: "Distribution",
      id: "release-distribution",
      current: false,
      content: <Distribution />,
      shouldSwitch: () =>
        release.id !== undefined &&
        release.id !== "" &&
        release.tracks?.length > 0,
    },
  };

  return (
    <section>
      <div className="bg-black w-full text-xd_yellow text-center px-20 lg:px-40 p-5">
        {prevRelease ? "Edit Release" : "Create New Release"}
      </div>
      <Tab tabs={tabs} pageTitle={pageTitle} defaultPage={pages[0]} />
      <div className="px-2 lg:px-40 text-right">
        {error ? <p className="text-xd_red">{error}</p> : ""}
      </div>
      <div className="flex justify-end gap-10 px-2 lg:px-40 my-20">
        <Button
          color="bg-transparent"
          className="rounded-md outline hover:outline-none"
          onClick={previous}
        >
          Previous
        </Button>
        <Button disabled={loading} onClick={next}>
          {loading ? <PuffLoader className="h-4 w-4" size={25} color="white" /> : "Save "}
        </Button>
      </div>
    </section>
  );
};

export default NewReleaseForm;
