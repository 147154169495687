import { useEffect, useState } from "react";
import { Track, TrackDetails } from "../../interfaces/release";
import FileUpload, { readFileAsDataURL } from "../FileUpload/FileUpload";
import {
  FileIcon,
  HamburgerIcon,
  HeadphoneIcon,
  PlayIcon,
  TrashIcon,
} from "../Icons/Icons";
import TrackListDetails from "../TrackListDetails/TrackListDetails";
import { useCreateReleaseContext } from "./CreateReleaseContext";
import CheckBox from "../CheckBox/CheckBox";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import { getFileName } from "../../utils/file";
import { IArtist } from "../../interfaces/artist";

function convertTrackToTrackDetails(track: Track): TrackDetails {
  // console.log(track);
  return {
    id: track.id,
    track: track.track_url,
    title: track.title,
    track_full_title: track.title,
    isrc_code: track.isrc_code,
    vocals_language: track.vocals_language,
    is_explicit: track.explicit_content,
    contributors: track.contributors,
    preview: {
      start_time: track.preview_start,
      end_time: track.preview_end,
    },
    // release_artist: track.release_artist,
    lyrics: track.lyrics,
  };
}

function CreateTrackListForm() {
  const { artists } = useDashboardContext();
  const { newRelease, release } = useCreateReleaseContext();
  const [trackDetails, setTrackDetails] = useState<TrackDetails[]>([]);
  const [editTrackList, setEditTrackList] = useState<boolean[]>([]);
  const [copyrightDoc, setCopyrightDoc] = useState<File>();
  const [copyrightDocName, setCopyRightDocName] = useState<string>("");

  /*
  useEffect(() => {
    console.log({ release });
  }, [release]);
  */

  // const [apiError, setApiError]: [any, React.Dispatch<any>] = useState({});
  // const [loading, setLoading]: [boolean, React.Dispatch<boolean>] =
  //   useState(false);

  function validateFile(file: File): boolean {
    const maxFileSize = 200_000_000; // 200mb
    if (file.size > maxFileSize) {
      // TODO: handle this properly
      alert(`File size must be no larger than ${maxFileSize / 1000}kb`);
      // e.target.value = "";
      return false;
    }
    return true;
  }

  async function replaceTrackFile(index: number, e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files?.length && validateFile(files[0])) {
      const file = files[0];
      const dataURL = await readFileAsDataURL(file);
      setTrackDetails((prev) => {
        const newDetails = [...prev];
        newDetails[index].track = dataURL as string;
        return newDetails;
      });
    }
    e.target.value = "";
  }

  async function addTrackFile(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files?.length && validateFile(files[0])) {
      const file = files[0];
      const name = file.name;
      const dataURL = await readFileAsDataURL(file);
      setTrackDetails((prev) => {
        return [
          ...prev,
          {
            track: dataURL,
            title: name.substring(0, name.length - 4),
          } as TrackDetails,
        ];
      });
      setEditTrackList((prev) => {
        return [...prev, false];
      });
    }
    e.target.value = "";
  }

  useEffect(() => {
    newRelease["release-track-list"].set("correct_label", "false");
    newRelease["release-track-list"].set("is_authorized", "false");
    newRelease["release-track-list"].set("is_approved", "false");
    //console.log("page rendering is done");
  }, []);

  useEffect(() => {
    console.log(release);
    newRelease["release-track-list"].set("release_id", release.id);
    // set the copyright doc name if it already exists
    if (release.copyright_doc) {
      setCopyRightDocName(getFileName(release.copyright_doc));
    }
    if (release.tracks) {
      setTrackDetails(
        release.tracks.map((track) => convertTrackToTrackDetails(track))
      );
      newRelease["release-track-list"].delete("track_details");
      // console.log("track details is being deleted");
    }
  }, [release]);

  useEffect(() => {
    if (copyrightDoc) {
      newRelease["release-track-list"].set("copyright_doc", copyrightDoc);
    }
  }, [copyrightDoc]);

  useEffect(() => {
    newRelease["release-track-list"].set(
      "track_details",
      JSON.stringify(trackDetails)
    );
    //console.log("trackdetails is being updated");
  }, [trackDetails]);

  function addTrackDetails(index: number, details: TrackDetails) {
    setTrackDetails((prev) => {
      const newDetails = [...prev];
      newDetails[index] = details;
      return newDetails;
    });
  }

  function deleteTrack(index: number) {
    setTrackDetails((prev) => {
      let oldDetails = [...prev];
      oldDetails.splice(index, 1);
      return oldDetails;
    });
    setEditTrackList((prev) => {
      let oldDetails = [...prev];
      oldDetails.splice(index, 1);
      return oldDetails;
    });
  }

  function toggleEditDetails(index: number) {
    setEditTrackList((prev) => {
      // console.log(prev)
      const oldDetails = [...prev];
      oldDetails[index] = !oldDetails[index];
      return oldDetails;
    });
  }

  function addCopyrightDoc(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files?.length && validateFile(files[0])) {
      //console.log("file is valid");
      setCopyrightDoc(files[0]);
      setCopyRightDocName(files[0].name);
    }
  }

  return (
    <div className="px-3 lg:px-20 my-10">
      <div className="">
        <div className="lg:flex gap-10">
          <FileUpload
            height="lg:h-[100px]"
            icon={<HeadphoneIcon />}
            children="Drag & Drop or Click to upload your track"
            row={"flex-row-reverse"}
            className="lg:gap-5 lg:ml-5 cursor-pointer"
            id="track"
            name="track"
            accept=".wav, .mp3"
            onChange={addTrackFile}
          />

          <ol className="list-disc text-xd_lightblack mt-5 ml-5">
            <li>File format must be .wav or .mp3 (wav is prefered)</li>
            <li>FIle size must be no larger than 200mb</li>
          </ol>
        </div>
      </div>
      <div className="flex justify-center my-10 box-border items-center">
        <div className="outline bg-xd_bleach w-full rounded-md box-border border-xd_grey">
          {trackDetails?.map((trackDetail, index) => {
            return <div key={index}>
              <div className="cursor-pointer">
                <div
                  className="flex lg:gap-10 items-center justify-between basis-[90%] p-8"
                  onClick={() => {
                    toggleEditDetails(index);
                  }}
                >
                  <div className="basis-1/6">
                    <HamburgerIcon />
                  </div>
                  <div className="basis-4/6 flex lg:justify-between gap-3">
                    <div className="">
                      <p className="text-xd_black">
                        {index + 1}. {trackDetail.title}
                      </p>
                      <p className="text-xd_grey">
                        by{" "}
                        {
                          artists.find(
                            (artist) => artist.id == release.release_artist_id
                          )?.name
                        }
                      </p>
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="w-12">
                        <PlayIcon />
                      </div>
                      <label className="text-[#2F80ED] text-xs hover:underline">
                        Replace audio file
                        <input
                          type="file"
                          className="hidden"
                          onChange={(e) => { replaceTrackFile(index, e) }}
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      deleteTrack(index);
                    }}
                    className="basis-1/6"
                  >
                    <TrashIcon />
                  </div>
                </div>
              </div>
              <div
                className={`${editTrackList[index] ? "" : "hidden"
                  } border-b border-xd_grey `}
              >
                <TrackListDetails
                  releaseArtist={release.release_artist ? release.release_artist : {id: release.release_artist_id} as IArtist}
                  onChange={(detail: any) => {
                    addTrackDetails(index, detail);
                  }}
                  details={trackDetail}
                  toggleEditDetails={() => {
                    toggleEditDetails(index);
                  }}
                />
              </div>
              {index != trackDetails.length - 1 ? (
                <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>
              ) : (
                ""
              )}
            </div>
          })}
        </div>
      </div>
      <div className="mt-5 items-center justify-center ">
        <div className="text-xd_grey">Upload Copyright Documentation (optional)</div>
        <div className="my-5">
          <FileUpload
            height="lg:h-[150px]"
            width="w-full"
            icon={<FileIcon />}
            children={
              copyrightDocName
                ? copyrightDocName
                : "Drag & Drop .docx/.txt or Click to upload copyright documentation"
            }
            row="flex-row-reverse"
            id="copyright"
            name="copyright"
            onChange={addCopyrightDoc}
          />
        </div>
      </div>
      <div className="my-5 items-center justify-center">
        <ol>
          <li className="flex justify-start items-center gap-4">
            <CheckBox
              id={"explicit"}
              type="checkbox"
              value="explicit"
              defaultChecked={release.is_authorized}
              onChange={(e) => {
                newRelease["release-track-list"].set(
                  "correct_label",
                  `${e.target.checked}`
                );
              }}
            />
            <label htmlFor="explicit">
              I understand my release may be rejected if I don't provide correct metadata.
            </label>
          </li>

          <li className="flex  justify-start items-center gap-4">
            <CheckBox
              id={"authorized"}
              type="checkbox"
              value="authorized"
              defaultChecked={release.is_authorized}
              onChange={(e) => {
                newRelease["release-track-list"].set(
                  "is_authorized",
                  `${e.target.checked}`
                );
              }}
            />
            <label htmlFor="authorized">
              I am authorized to distribute this music to stores and all territories.
            </label>
          </li>

          <li className="flex  justify-start items-center gap-4">
            <CheckBox
              id={"impersonation"}
              type="checkbox"
              value="approved"
              defaultChecked={release.is_correct_label}
              onChange={(e) => {
                newRelease["release-track-list"].set(
                  "is_approved",
                  `${e.target.checked}`
                );
              }}
            />
            <label htmlFor="impersonation">
              I'm not using any artist name, song title or album title without approval.
            </label>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default CreateTrackListForm;
