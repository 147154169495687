export function getReleaseStatus(status: string) {
  status = status.toLowerCase()
  switch (status) {
    case "submitted":
      return (
        <Status text={status} color="bg-[#0077b6]" />
      );
    case "pending": case "draft":
      return (
        <Status text={status} color="bg-xd_yellow" />
      );
    case "failed":
      return (
        <Status text={status} color="bg-[#EB5757]" />
      );
    case "approved":
      return (
        <Status text={status} color="bg-[#2F80ED]" />
      );
    case "live":
      return (
        <Status text={status} color="bg-[#6FCF97]" />
      );
    case "paid":
      return (
        <Status text={status} color="bg-[#6FCF97]" />
      );
    default:
      return (
        <Status text={status} color="bg-xd_lightblack" />
      );
  }
}

function Status({ text, color }: { text: string, color: string }) {
  return (
    <span className={`uppercase py-2 px-2 rounded-lg text-xs text-white ${color}`}>
      {text}
    </span>
  );
}
