import { Link } from "react-router-dom";
import Button from "../Button/Button";
import WhiteLogo from "../Icons/LandingLogo";
import HomeMobileSideBar from "../SideBar/HomeMobileSideBar";
import { ReactComponent as YellowLogo } from "../../assets/logo-yellow.svg";
import { useState } from "react";

interface Props {
  background?: boolean;
}

const Navbar = ({ background }: Props) => {
  const [logo, setLogo] = useState(<WhiteLogo />);
  const token = localStorage.getItem("token");
  return (
    <nav
      onMouseOver={() => {
        setLogo(<YellowLogo />);
      }}
      onMouseLeave={() => {
        setLogo(<WhiteLogo />);
      }}>
      <div
        className={`flex flex-row w-full absolute top-0 text-white md:hover:text-xd_black ${
          background ? "bg-[#333333]" : "bg-transparent"
        } md:hover:bg-white px-2 lg:px-32 py-5 z-10`}>
        <div className="basis-1/3 w-full md:block lg:block hidden">
          <h1 className="font-sans font-bold text-medium">
            <div className="h-[53px] w-[80px]">{logo}</div>
          </h1>
        </div>
        <div className="text-small basis-1/3 text-center md:flex justify-center align-middle hidden">
          <div className="py-3">
            <Link className=" " to="/">
              Home
            </Link>

            <Link className="px-10" to="/partners">
              Partners
            </Link>

            <a className="" target={"_blank"} href="https://support.xdistromusic.com">
              Support
            </a>
          </div>
        </div>
        {token ? (
          <div className="basis-1/3 text-right md:block hidden">
            <Link className="lg:pl-10" to="/dashboard/my-music">
              <Button> Dashboard </Button>
            </Link>
          </div>
        ) : (
          <div className="basis-1/3 text-right md:block hidden">
            <Link to="/login">Log in</Link>
            <Link className="lg:pl-10" to="/signup">
              <Button> Get Started </Button>
            </Link>
          </div>
        )}
      </div>
      <div className="md:hidden bg-[#333333] w-full py-2 px-5 fixed z-10">
        <HomeMobileSideBar />
      </div>
    </nav>
  );
};

export default Navbar;

