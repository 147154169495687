import { RightArrowIcon } from "../Icons/MusicDistributionIconList";
import FaqSubList from "./FaqSubList";

const Faq = () => {
  return (
    <div className="my-20 container mx-auto">
      <h1 className="font-bold text-4xl text-center">
        Frequently Asked Questions
      </h1>

      <div className="my-14">
        <FaqSubList />
      </div>

      <div className="flex">
        <div className="basis-1/2 font-semibold text-medium">
          <a href="https://support.xdistromusic.com" target={"_blank"}>More FAQ’s</a>
        </div>
        <div className="basis-1/2 text-end">
          <a href="https://support.xdistromusic.com" target={"_blank"} className="inline-block">
            <RightArrowIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Faq;

